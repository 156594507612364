import * as Sentry from "@sentry/capacitor";
import * as SentryReact from "@sentry/react";
import {DeviceId} from "@capacitor/device/dist/esm/definitions";
import {Device} from "@capacitor/device";
import {ConfigItem, getCurrentConfig} from "./config";
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

const currentConfig: ConfigItem =  getCurrentConfig().config;
const sentryDsn: string | undefined =  currentConfig.sentry?.dsn;
const logRocket: string | undefined =  currentConfig.logRocket;
const sentryEnvironment: string | undefined =  currentConfig.sentry?.environment;

if (sentryDsn) {
    Sentry.init({
        // nc-mobile
        dsn: sentryDsn,
        environment: sentryEnvironment,
        release: process.env.RELEASE_NAME,
        sampleRate: 0.1,
    }, SentryReact.init);

    Device.getId().then(({identifier}: DeviceId)=>{
        Sentry.setUser({
            id: identifier,
        });
    })
}


if(logRocket && Math.random() <= 0.1){
    LogRocket.init(logRocket, {release: process.env.RELEASE_NAME});
    setupLogRocketReact(LogRocket);
    LogRocket.getSessionURL(sessionURL => {
        if (!sentryDsn) {
            return;
        }
        Sentry.configureScope(scope => {
            scope.setExtra("sessionURL", sessionURL);
        });
    });
}
